import React, { useState } from 'react'
import { IBookPreview } from '../Types'
import loadResource from '../utils/loadResource'
import BookIDInput from './BookIDInput'

const BookSelector = ({ setShow, books, onBookIDChange }: { setShow: React.Dispatch<React.SetStateAction<boolean>>, books: IBookPreview[], onBookIDChange: (bookID: string) => void }) => {

  return (
    <div>
      <BookIDInput onInput={onBookIDChange} />
      <ul style={{ listStyle: 'none', display: 'grid', gridTemplateColumns: 'auto auto auto auto' }}>
        {books.map(book =>
          <li style={{ margin: '1rem' }} key={book.id} onClick={() => { onBookIDChange(book.id.toString()); setShow(false) }}>
            <img referrerPolicy='no-referrer' style={{ height: '6rem', display: 'block' }} src={`${loadResource(book.id)}previews/${book.image}`} />
            <span style={{ marginLeft: '2rem', fontSize: '0.9em' }}>
              {book.name}
            </span>
          </li>
        )}
      </ul>
    </div>
  )
}


export default BookSelector
