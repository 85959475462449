import '../css/menuBar.css'
import BookIDInput from './BookIDInput'
import Header from './Header'
import { IBook, IPageItem } from '../Types'
import { useEffect, useState } from 'react'
import Tabs from './Tabs'

const MenuBar = ({ currentPage, onPageChange, toggleBookSelection, book }: { currentPage: any, onPageChange: React.Dispatch<React.SetStateAction<number>>, toggleBookSelection: () => void, book: IBook }) => {
  const [pageInput, setPageInput] = useState(currentPage)

  useEffect(() => {
    setPageInput(currentPage)
  }, [currentPage])

  const { navList, pageList, answers } = book

  const navListAndAnswers = navList.length > 1 ? [...navList.slice(0, navList.length - 1), ...answers.map(answer => {
    return {
      label: answer.name,
      playOrder: (answer.page + 1).toString(),
      src: ''
    }
  }), navList[navList.length - 1]] : navList

  const getChapterIndex = (page: number): number => {
    console.log({ navList, navListAndAnswers, page })
    return navListAndAnswers.findIndex(nav =>
      parseInt(nav.playOrder) > parseInt(pageList[page].playOrder)) >= 0 ?
      navListAndAnswers.findIndex(nav =>
        parseInt(nav.playOrder) > parseInt(pageList[page].playOrder)) - 1 :
      navListAndAnswers.length - 1
  }

  const getChapterName = (page: number): string => {
    return navListAndAnswers[getChapterIndex(page)].label
  }

  const chapter = getChapterIndex(currentPage)

  return (
    <div className='menu'>
      <button onClick={toggleBookSelection}>Books</button>
      <Header />

      {/* TODO consider displaying invalid inputs */}
      <input style={{ width: '5ch' }} type="text" name="" id="" value={pageList[pageInput]?.label ?? ''} onChange={e => setPageInput((current: number) => {
        const pageNumber = pageList.findIndex(page => page.label === e.target.value)
        if (!(pageNumber < 0) && !(pageNumber >= pageList.length)) onPageChange(pageNumber)
        return pageNumber
      })} />
      {/* TODO improve value */}
      {navList.length ?
        <>
          <select style={{ width: '6ch' }}
            value={chapter}
            onChange={e =>
              onPageChange(parseInt(navListAndAnswers[parseInt(e.target.value)].playOrder) - 1)
            }
          >
            {navListAndAnswers.map(((nav, i) =>
              <option value={i} key={i}>
                {nav.label}
              </option>
            ))}
          </select>
          <Tabs book={book} currentPage={currentPage} onPageChange={onPageChange} getChapterName={getChapterName} ></Tabs>
        </>
        : null
      }
      <button className='pageButton' onClick={() => onPageChange((current) => {
        const newPage = current - 1
        return newPage < 0 ? 0 : newPage
      })}>&lt;</button>
      <button className='pageButton' onClick={() => onPageChange((current) => {
        const newPage = current + 1
        return newPage >= pageList.length ? pageList.length - 1 : newPage
      })}>&gt;</button>
    </div >
  )
}


export default MenuBar
