import { resourceSvrUrl } from '../config'

const loadResource = (n: string, url?: string) => {
  for (n = n.toString(); n.length < 8; ) n = '0' + n
  let t = 'r' + n.substr(0, 2)
  let i = '/r' + n.substr(0, 4)
  let r = '/r' + n.substr(0, 6)
  let u = '/r' + n.substr(0, 8) + '/'
  return resourceSvrUrl + t + i + r + u
}

export default loadResource
