import '../css/viewer.css'
import { ILinkItem } from '../Types'

const Viewer = ({ page }: { page: ILinkItem }) => {
  return (
    <div className='container'>
      <div className='page'>
        <img referrerPolicy='no-referrer' src={page?.href?.replace('xhtml', 'jpg')?.replace('OPS', 'OPS/images')} />
      </div>
    </div>
  )
}


export default Viewer
