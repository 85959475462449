import { ILinkItem } from '../Types'

const MenuLinkList = ({ links }: { links: ILinkItem[] }) => {
  return (
    <div>
      <ul style={{ listStyle: 'none' }}>
        {links.map(link =>
          <li key={link.id}><a href='' onClick={async e => {
            e.preventDefault()
            const res = await fetch(link.href ?? '')
            const body = await res.text()
            const doc = (new DOMParser()).parseFromString(body, 'text/html')
            window.open(doc.querySelector('a')?.href ?? '')
          }}>{link.name?.replaceAll(/(wdgt|\/|\.|index\.html)/g, '')}</a></li>
        )}
      </ul>
    </div >
  )
}

export default MenuLinkList
