import '../css/menu.css'
import { IBook, IBookPreview } from '../Types'
import BookSelector from './BookSelector'
import MenuLinkList from './MenuLinkList'

const Menu = ({ book, books, onBookIDChange, show, setShow }: { book: IBook, books: IBookPreview[], onBookIDChange: (bookID: string) => void, show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>> }) => {
  return (
    <>
      {
        show ?
          <div className='menu-container'>
            <BookSelector books={books} onBookIDChange={onBookIDChange} setShow={setShow} ></ BookSelector>
            <MenuLinkList links={book.content.others} ></MenuLinkList>
          </div >
          : null
      }
    </>
  )
}

export default Menu
