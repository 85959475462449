import React, { useState } from 'react'

const BookIDInput = ({ onInput }: { onInput: (bookID: string) => void }) => {
  const [bookID, setBookID] = useState('703741')

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onInput(bookID)
  }

  return (
    <form onSubmit={onSubmit}>
      <label>Book ID</label>
      <input type="text" value={bookID} onChange={(e) => setBookID(e.target.value)} />
      <input type="submit" value="Search" />
    </form>
  )
}

export default BookIDInput
