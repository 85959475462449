import { useEffect, useState } from 'react'
import loadResource from './utils/loadResource'
import Viewer from './components/Viewer'
import MenuBar from './components/MenuBar'
import BookSelector from './components/BookSelector'
import { IBook, IBookPreview, ILinkItem, IPageItem } from './Types'
import { filePath } from './config'
import { fetchXML } from './utils/loadData'
import Menu from './components/Menu'

const books: IBookPreview[] = [
  {
    "seriesID": "666684",
    "id": "1089077",
    "name": "Edexcel AS and A level Mathematics: Statistics and Mechanics Year 1/AS Practice Book",
    "image": "pe_as_a_lev_math_sm_yr1_pb_cover.jpg"
  },
  {
    "seriesID": "666684",
    "id": "1089078",
    "name": "Edexcel A level Mathematics: Statistics and Mechanics Year 2 Practice Book",
    "image": "pe_a_lev_math_sm_yr2_pb_cover.jpg"
  },
  {
    "seriesID": "666684",
    "id": "1089183",
    "name": "Edexcel AS and A level Mathematics: Pure Mathematics 1 Year 1/AS Practice Book",
    "image": "pe_as_a_lev_math_pm1_yr1_as_pb_cover.jpg"
  },
  {
    "seriesID": "666684",
    "id": "1089184",
    "name": "Edexcel A level Mathematics: Pure Mathematics Year 2 Practice Book",
    "image": "pe_a_lev_math_pm_yr2_pb_cover.jpg"
  },
  {
    "seriesID": "666684",
    "id": "662374",
    "name": "01. Edexcel AS and A level Mathematics Pure Mathematics Year 1 ActiveBook",
    "image": "pure_maths_1_cvr.jpg"
  },
  {
    "seriesID": "666684",
    "id": "674039",
    "name": "02. Edexcel AS and A level Mathematics Statistics and Mechanics Year 1 ActiveBook",
    "image": "stats_and_mech_1_cvr.jpg"
  },
  {
    "seriesID": "666684",
    "id": "680025",
    "name": "03. Edexcel A level Mathematics Pure Mathematics Year 2 ActiveBook",
    "image": "pure_maths_2_cvr.jpg"
  },
  {
    "seriesID": "666684",
    "id": "703733",
    "name": "04. Edexcel A level Mathematics Statistics and Mechanics Year 2 ActiveBook",
    "image": "stats_and_mech_2_cvr.jpg"
  },
  {
    "seriesID": "666684",
    "id": "703734",
    "name": "05. Edexcel AS and A level Further Mathematics Core Pure Mathematics Book 1 ActiveBook",
    "image": "core_pure_1_cvr.jpg"
  },
  {
    "seriesID": "666684",
    "id": "703735",
    "name": "08. Edexcel AS and A level Further Mathematics Decision Mathematics 1 ActiveBook",
    "image": "dec_maths_1_cvr.jpg"
  },
  {
    "seriesID": "666684",
    "id": "703736",
    "name": "07. Edexcel AS and A level Further Mathematics Further Mechanics 1 ActiveBook",
    "image": "further_mech_1_cvr.jpg"
  },
  {
    "seriesID": "666684",
    "id": "703737",
    "name": "06. Edexcel AS and A level Further Mathematics Further Statistics 1 ActiveBook",
    "image": "further_stats_1_cvr.jpg"
  },
  {
    "seriesID": "666684",
    "id": "703738",
    "name": "09. Edexcel AS and A level Further Mathematics Further Pure Mathematics 1 ActiveBook",
    "image": "further_pure_1_cvr.jpg"
  },
  {
    "seriesID": "666684",
    "id": "703741",
    "name": "10. Edexcel A level Further Mathematics Core Pure Mathematics Book 2 ActiveBook",
    "image": "core_pure_2_cvr.jpg"
  },
  {
    "seriesID": "666684",
    "id": "703742",
    "name": "14. Edexcel AS and A level Further Mathematics Further Pure Mathematics 2 ActiveBook",
    "image": "further_pure_2_cvr.jpg"
  }
]

const App = () => {
  const [showBookSelection, setShowBookSelection] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [book, setBook] = useState<IBook>({
    id: '',
    pageList: [],
    navList: [],
    content: {
      all: [],
      pages: [],
      technical: [],
      others: []
    },
    answers: []
  })

  document.onkeydown = function (e) {
    switch (e.keyCode) {
      case 37:
        setCurrentPage(currentPage - 1)
        break
      case 38:
        setCurrentPage(currentPage + 1)
        break
      case 39:
        setCurrentPage(currentPage + 1)
        break
      case 40:
        setCurrentPage(currentPage - 1)
        break
    }
  }

  const onInput = async (bookID: string) => {
    console.log(bookID)
    const ncx = await fetchXML(`${loadResource(bookID)}${filePath}/book.ncx`)
    const opf = await fetchXML(`${loadResource(bookID)}${filePath}/book-short.opf`)
    console.log(ncx, opf)

    const items = Array.from(opf.querySelectorAll('manifest item')).map(item => {
      return {
        id: item.attributes.getNamedItem('id')?.value,
        name: item.attributes.getNamedItem('href')?.value,
        href: item.attributes.getNamedItem('href')?.value ? `${loadResource(bookID)}${filePath}/${item.attributes.getNamedItem('href')?.value}` : undefined
      }
    })

    const pageList: IPageItem[] = Array.from(ncx.querySelectorAll('pageTarget')).map(page => {
      return {
        // id: page.attributes.getNamedItem('id')?.value,
        // type: page.attributes.getNamedItem('type')?.value,
        // value: page.attributes.getNamedItem('value')?.value,
        playOrder: page.attributes.getNamedItem('playOrder')?.value ?? '',
        label: page.querySelector('text')?.innerHTML ?? '',
        src: page.querySelector('content')?.attributes.getNamedItem('src')?.value ?? ''
      }
    })

    const navList: IPageItem[] = Array.from(ncx.querySelectorAll('navPoint')).map(page => {
      return {
        // id: page.attributes.getNamedItem('id')?.value,
        // class: page.attributes.getNamedItem('class')?.value,
        playOrder: page.attributes.getNamedItem('playOrder')?.value ?? '',
        label: page.querySelector('text')?.innerHTML ?? '',
        src: page.querySelector('content')?.attributes.getNamedItem('src')?.value ?? ''
      }
    })

    console.log({ items, pageList })

    const tests = {
      isPage: (item: ILinkItem) => item.name && /.*-\d{3}.xhtml/.test(item.name),
      isTechnical: (item: ILinkItem) => item.name && /(toc.xhtml|.css$|.opf$|.ncx$)/.test(item.name)
    }

    const content = {
      all: items,
      pages: items.filter(tests.isPage),
      technical: items.filter(tests.isTechnical),
      others: items.filter(item => !tests.isPage(item) && !tests.isTechnical(item))
    }

    const parser = new DOMParser()
    const answerStartPage = parseInt(navList[navList.length - 2].playOrder) - 1

    const transcriptAnswers = await Promise.all(content.pages.slice(answerStartPage, pageList.length - 1).map(async (answer) => {
      if (!answer.href) return ''
      const res = await fetch(answer.href)
      const body = await res.text()
      const doc = parser.parseFromString(body, 'text/html')
      return doc?.querySelector('#alt-wrapper p')?.innerHTML ?? ''
    }))

    console.log({ transcriptAnswers })

    const questions = transcriptAnswers.join().match(/(Exercise [0-9]+[A-Z]|Mixed exercise [0-9]+)/g)

    const answers = questions?.map(question => {
      return {
        name: question,
        page: answerStartPage + transcriptAnswers.findIndex(transcriptAnswer => transcriptAnswer.includes(question))
      }
    }) ?? []

    setBook({
      id: bookID,
      pageList,
      navList,
      content,
      answers
    })

    console.log({ book })
  }

  return (
    <div className='App' style={{ height: '100vh' }}>
      <div>
        <Viewer page={book?.content?.pages[currentPage]}></Viewer>
        <MenuBar book={book} currentPage={currentPage} onPageChange={setCurrentPage} toggleBookSelection={() => setShowBookSelection(pre => !pre)} ></MenuBar>

        <Menu book={book} books={books} onBookIDChange={onInput} show={showBookSelection} setShow={setShowBookSelection}></Menu>
      </div>
    </div >
  )
}

export default App
