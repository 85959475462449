import { useEffect, useState } from 'react'
import { IBook, ITab } from '../Types'

const defaultTab = { name: 'Tab', page: 0 }


const Tabs = ({ book, currentPage, onPageChange, getChapterName }: { book: IBook, currentPage: any, onPageChange: React.Dispatch<React.SetStateAction<number>>, getChapterName: (page: number) => string }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const [tabs, setTabs] = useState<ITab[]>([defaultTab])

  const tabName = (page: number): string => `${book.pageList[page].label} - ${getChapterName(page)}`

  useEffect(() => {
    const storedTabs = localStorage.getItem(`${book.id}-tabs`)
    if (!storedTabs) {
      setTabs([defaultTab])
      return
    }

    setTabs(JSON.parse(storedTabs))
    setSelectedTab(parseInt(localStorage.getItem(`${book.id}-selectedTab`) ?? '0'))
  }, [book])

  useEffect(() => {
    localStorage.setItem(`${book.id}-tabs`, JSON.stringify(tabs))
  }, [tabs])

  useEffect(() => {
    onPageChange(tabs[selectedTab].page)
    localStorage.setItem(`${book.id}-selectedTab`, selectedTab.toString())
  }, [selectedTab])

  useEffect(() => {
    const temp = [...tabs]
    console.log({ temp, selectedTab })
    temp[selectedTab].page = currentPage
    temp[selectedTab].name = tabName(currentPage)
    setTabs(temp)
  }, [currentPage])

  const addTab = () => {
    setTabs([...tabs, {
      name: tabName(currentPage),
      page: 0
    }])

    return tabs.length
  }

  const removeTab = (tabNumber: number) => {
    if (tabs.length === 1) return
    setTabs([...tabs.filter((_, i) => i !== tabNumber)])
    setSelectedTab(tabNumber - 1)
  }

  return (
    <div style={{ display: 'flex' }}>
      <select style={{ height: '100%', width: '30ch' }} value={selectedTab} onChange={e => setSelectedTab(parseInt(e.target.value))} >
        {tabs.map((tab, i) =>
          <option key={i} value={i}>
            {tab.name}
          </option>
        )}
      </select>
      <button style={{ height: '100%' }} onClick={() => setSelectedTab(addTab())}>Add Tab</button>
      <button style={{ height: '100%' }} onClick={() => removeTab(selectedTab)}>Delete Tab</button>
    </div>
  )
}

export default Tabs
